import "./footer.component.scss";
import { NavLink, useNavigate } from "react-router-dom";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faAngellist,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import { Button, IconButton, Drawer, Divider, TextField } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SendIcon from "@mui/icons-material/Send";

function FooterComponent({ pathname }) {
  const navigate = useNavigate();

  const goToLink = (id, isScroll, isSmooth) => {
    if (isScroll) {
      document
        .getElementById(id)
        ?.scrollIntoView({ behavior: isSmooth ? "smooth" : "auto" });
    } else {
      navigate(id);
    }
  };

  return (
    <footer className="footer-component">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-3 text-start">
            <Link to="/">
              <img
                className="logo-image"
                src={require("../../assets/images/flux_logo_long_white.png")}
              />
              {/* <div className="logo-text d-inline-block">
                FLUX <span className="text-primary">AUTO</span>
              </div> */}
            </Link>
          </div>
          <div className="mobile-spacer"></div>

          <div className="col-12 col-lg-9 text-center text-lg-end">
            <div className="nav-menu">
              {pathname === "/" && (
                <Button
                  className={"nav-menu-button"}
                  onClick={() => goToLink("in-action", true, true)}
                >
                  In Action
                </Button>
              )}

              {pathname === "/" && (
                <Button
                  className={"nav-menu-button"}
                  onClick={() => goToLink("why-automate", true, true)}
                >
                  Why Automate?
                </Button>
              )}

              {pathname === "/" && (
                <Button
                  className={"nav-menu-button"}
                  onClick={() => goToLink("why-choose", true, true)}
                >
                  Why Flux?
                </Button>
              )}

              {pathname !== "/" && (
                <Button
                  className={"nav-menu-button"}
                  onClick={() => goToLink("home", false, true)}
                >
                  Home
                </Button>
              )}

              <Button
                className={"nav-menu-button"}
                onClick={() => goToLink("technology", false, true)}
              >
                Technology
              </Button>

              <Button
                className={"nav-menu-button"}
                onClick={() => goToLink("careers", false, true)}
              >
                Careers
              </Button>

              {!pathname.toLowerCase().startsWith("/privacy") &&
                !pathname.toLowerCase().startsWith("/terms") && (
                  <Button
                    className={"nav-menu-button"}
                    onClick={() => goToLink("contact-us", true, true)}
                  >
                    Contact Us
                  </Button>
                )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-12 text-start">
            <b className="text-primary">Stay in Touch</b>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 text-start">
            <form
              acceptCharset="utf-8"
              action="https://formspree.io/f/xwkajbny"
              method="post"
            >
              <div className="row">
                <div className="col-12">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="email-input">
                        <input
                          placeholder="Email Address"
                          type="email"
                          name="_replyto"
                          id="email-address"
                          required=""
                        />
                        <input
                          type="hidden"
                          name="_subject"
                          id="email-subject"
                          value="Subscription"
                        />
                        <Button
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{}}
                        >
                          <SendIcon
                            className="text-white1"
                            style={{ fontSize: 32, marginBottom: 0 }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-lg-6 text-end">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 text-start">
                <div className="mobile-spacer"></div>
                <div className="icon-container gradient2">
                  <LocalPhoneRoundedIcon
                    className="text-black1"
                    style={{ fontSize: 32 }}
                  />
                </div>
                <a
                  className="link-footer"
                  href="tel:+1 (737) 232-7272"
                  style={{ verticalAlign: "10px" }}
                >
                  +1 (737) 232-7272
                </a>
              </div>
              <div className="col-12 col-lg-6 text-start">
                <div className="mobile-spacer"></div>
                <div className="icon-container gradient2">
                  <MailRoundedIcon
                    className="text-black1"
                    style={{ fontSize: 32 }}
                  />
                </div>
                <a
                  className="link-footer"
                  href="mailto:contact@fluxauto.xyz"
                  style={{ verticalAlign: "10px" }}
                >
                  contact@fluxauto.xyz
                </a>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />

        <div className="row">
          <div className="col-12 text-center">
            &copy; 2024 Flux Auto, Inc. All Rights Reserved -{" "}
            <NavLink to="/privacy" className="link-footer">
              <span>Privacy Policy</span>
            </NavLink>{" "}
            -{" "}
            <NavLink to="/terms" className="link-footer">
              <span>Terms of Use</span>
            </NavLink>
          </div>
        </div>
        <div className="mobile-spacer"></div>
        <div className="mobile-spacer"></div>
        {/* <div className="row">
          <div className="col-12 col-lg-3 text-start">
            <div className="font-size-lg text-white1">Resources</div>
            <NavLink to="/technology" className="nav-footer">
              Technology
            </NavLink>
            <NavLink to="/careers" className="nav-footer">
              Careers
            </NavLink>
            <br />
            <Divider style={{ width: "80%" }} />
            <br />
            <div>Flux Auto &copy; 2024</div>
            <div className="social-icons">
              <a
                className="link-footer"
                href="https://www.linkedin.com/company/fluxauto/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                className="link-footer"
                href="https://twitter.com/fluxauto"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                className="link-footer"
                href="https://angel.co/fluxauto"
                target="_blank"
              >
                <FontAwesomeIcon icon={faAngellist} />
              </a>
            </div>
            <div className="mobile-spacer"></div>
          </div>

          <div className="col-12 col-lg-6 text-start">
            <div className="font-size-lg text-white1">Contact Us</div>
            <div className="row">
              <div className="col-12">Flux Auto, Inc.</div>
              <div className="col-6 col-md-5">
                <div>Houston, TX</div>
                <br />
                <div>
                  <a className="link-footer" href="tel:+1 (415) 707-0123">
                    <LocalPhoneRoundedIcon
                      className="text-black2"
                      style={{ fontSize: 20 }}
                    />{" "}
                    +1 (415) 707-0123
                  </a>
                </div>
              </div>
              <div className="col-6 col-md-5">
                <div>Bangalore, India</div>
                <br />
                <div>
                  <a className="link-footer" href="tel:+91 99-161-33533">
                    <LocalPhoneRoundedIcon
                      className="text-black2"
                      style={{ fontSize: 20 }}
                    />{" "}
                    +91 99-161-33533
                  </a>
                </div>
              </div>
            </div>
            <div className="mobile-spacer"></div>
          </div>
          <div className="col-12 col-lg-3 text-start">
            <div className="font-size-lg text-white1">
              Want to work with us?
            </div>
            <div>
              <a className="link-footer" href="mailto:careers@fluxauto.xyz">
                <MailRoundedIcon
                  className="text-black2"
                  style={{ fontSize: 20 }}
                />{" "}
                careers@fluxauto.xyz
              </a>
            </div>
            <br></br>
            <div className="font-size-lg text-white1">
              Reach out to us for anything else
            </div>
            <div>
              <a className="link-footer" href="mailto:careers@fluxauto.xyz">
                <MailRoundedIcon
                  className="text-black2"
                  style={{ fontSize: 20 }}
                />{" "}
                contact@fluxauto.xyz
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  );
}

export default FooterComponent;
