import { useState, useEffect, useLayoutEffect } from "react";
import "./investors.component.scss";
import { Routes, Route, Link, NavLink, useNavigate } from "react-router-dom";
import { Button, IconButton, Drawer, Divider, TextField } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

function InvestorsSectionComponent() {
  return (
    <section className="section-investors" id="investors">
      <div
        className="gradient1"
        style={{ padding: "32px 0px", marginBottom: "32px" }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="heading-font">Investors</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 image-container">
            <img
              src={require("../../assets/images/Y_Combinator_logo_text_wordmark.png")}
              style={{ height: "80px" }}
            />
          </div>
          <div className="col-12 col-lg-4 image-container">
            <img
              src={require("../../assets/images/jam_fund.png")}
              style={{ height: "40px" }}
            />
          </div>
          <div className="col-12 col-lg-4 image-container">
            <img
              src={require("../../assets/images/Metaplanet Logo.png")}
              style={{ height: "60px" }}
            />
          </div>
          <div className="col-12 col-lg-4 image-container">
            <img
              src={require("../../assets/images/7pc logo.png")}
              style={{ height: "70px" }}
            />
          </div>
          <div className="col-12 col-lg-4 image-container">
            <img
              src={require("../../assets/images/asymmetry-ventures2.png")}
              style={{ height: "70px" }}
            />
          </div>
          <div className="col-12 col-lg-4 image-container">
            <img
              src={require("../../assets/images/Soma Logo.png")}
              style={{ height: "60px" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default InvestorsSectionComponent;
