import { useState, useEffect, useRef } from "react";
import "./progress-slider.component.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, IconButton, Drawer, Divider, Dialog } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useLayoutEffect } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import YouTube from "react-youtube";

function ProgressSliderComponent({ data, overlay }) {
  const [state, setState] = useState(0);
  const settings = {
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000,
    dots: true,
    arrows: false,
    dotsClass: "slider-dots",
    fade: true,
  };

  const slider = useRef();

  const setView = (index) => {
    console.log(index);
    slider.current?.slickGoTo(index);
    setState(index);
  };

  const [dialogOpen, setDialogOpen] = useState(null);

  return (
    <div className="progress-slider">
      <div>
        <Slider
          {...settings}
          ref={slider}
          afterChange={(index) => {
            setState(index);
          }}
          style={{ position: "relative" }}
        >
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[0].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              size="large"
              style={{ marginTop: "0px", marginBottom: "0px" }}
              variant="contained"
              onClick={() => {
                setDialogOpen(data[0].youtubeId);
              }}
            >
              Watch Full Video
            </Button>
          </div>
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[1].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              size="large"
              style={{ marginTop: "0px", marginBottom: "0px" }}
              variant="contained"
              onClick={() => {
                setDialogOpen(data[1].youtubeId);
              }}
            >
              Watch Full Video
            </Button>
          </div>
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[2].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              size="large"
              style={{ marginTop: "0px", marginBottom: "0px" }}
              variant="contained"
              onClick={() => {
                setDialogOpen(data[2].youtubeId);
              }}
            >
              Watch Full Video
            </Button>
          </div>
          <div className="video-container">
            <video autoPlay loop muted playsInline>
              <source src={data[3].videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              size="large"
              style={{ marginTop: "0px", marginBottom: "0px" }}
              variant="contained"
              onClick={() => {
                setDialogOpen(data[3].youtubeId);
              }}
            >
              Watch Full Video
            </Button>
          </div>
        </Slider>
        {overlay && (
          <div className="overlay">
            <CenterFocusWeakIcon
              className="text-white1"
              style={{ fontSize: 28, marginBottom: 0 }}
            />
            <h4>{overlay.title}</h4>
            <p>{overlay.description}</p>
          </div>
        )}
      </div>

      <Dialog
        onClose={() => {
          setDialogOpen(null);
        }}
        open={dialogOpen !== null}
      >
        <DialogTitle style={{ padding: "8px" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setDialogOpen(null);
            }}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              background: "#FFFFFF !important",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "0px 8px 8px 8px" }}>
          <YouTube
            videoId={dialogOpen}
            opts={{
              width: "100%",
              height: "100%",
              playerVars: {
                autoplay: 1,
              },
            }}
            onReady={(event) => {
              event.target.playVideo();
            }}
          />
        </DialogContent>
      </Dialog>

      <div className="d-none d-lg-block">
        <div className="row" style={{ marginTop: "16px" }}>
          <div className="col-12 col-lg-3">
            <div
              className={
                state === 0 ? "text-white1 description" : "description"
              }
              onClick={() => setView(0)}
            >
              <h4>{data[0].title}</h4>
              <p>{data[0].description}</p>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div
              className={
                state === 1 ? "text-white1 description" : "description"
              }
              onClick={() => setView(1)}
            >
              <h4>{data[1].title}</h4>
              <p>{data[1].description}</p>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div
              className={
                state === 2 ? "text-white1 description" : "description"
              }
              onClick={() => setView(2)}
            >
              <h4>{data[2].title}</h4>
              <p>{data[2].description}</p>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div
              className={
                state === 3 ? "text-white1 description" : "description"
              }
              onClick={() => setView(3)}
            >
              <h4>{data[3].title}</h4>
              <p>{data[3].description}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none">
        <div
          className="row text-white1"
          style={{ marginTop: "16px", marginBottom: "0px" }}
        >
          {state === 0 && (
            <div
              className="col-12 col-lg-3 description"
              onClick={() => setView(0)}
            >
              <h4>{data[0].title}</h4>
              <p>{data[0].description}</p>
            </div>
          )}

          {state === 1 && (
            <div
              className="col-12 col-lg-3 description"
              onClick={() => setView(1)}
            >
              <h4>{data[1].title}</h4>
              <p>{data[1].description}</p>
            </div>
          )}

          {state === 2 && (
            <div
              className="col-12 col-lg-3 description"
              onClick={() => setView(2)}
            >
              <h4>{data[2].title}</h4>
              <p>{data[2].description}</p>
            </div>
          )}

          {state === 3 && (
            <div
              className="col-12 col-lg-3 description"
              onClick={() => setView(3)}
            >
              <h4>{data[3].title}</h4>
              <p>{data[3].description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProgressSliderComponent;
